
import Api from "@/services/Api";
import { computed, defineComponent, onMounted, ref, Ref, watch } from "vue";
import { useRouter } from "vue-router";
import { Modal } from "bootstrap";
import Title from "@/components/Title.vue";
import Button from "@/components/Button.vue";
import ModalCreateEditBandeira from "./components/ModalCreateEditBandeira.vue";
import useEmitter from "@/composables/Emmiter";

interface Bandeira {
  codBandeira: number | null;
  cor1: string;
  cor2: string;
  cor3: string;
  created_at: string;
  descricao: string;
  fornecedorFiltroAc: string | null;
  fornecedorFiltroAr: string | null;
  fornecedorFiltroCombustivel: string | null;
  fornecedorFiltroOleo: string | null;
  fundoBg: string;
  imgBandeira: string;
  imgDestaque: string;
  imgModal: string | null;
  updated_at: string;
}

export default defineComponent({
  name: "Bandeiras",
  components: {Title, Button, ModalCreateEditBandeira},
  props: [],
  setup() {
    const router = useRouter();
    const paramsProdutos: Ref<any> = ref({ pagina: 1, limite: 20, input: '' })
    const emitter = useEmitter()
    const auxModal: Ref<any> = ref(null);
    const bandeiras: Ref<Bandeira[]> = ref([]);
    const bandeiraSelected: Ref<Bandeira> = ref({
      codBandeira: null,
            cor1: "",
            cor2: "",
            cor3: "",
            created_at: "",
            descricao: "",
            fornecedorFiltroAc: "",
            fornecedorFiltroAr: "",
            fornecedorFiltroCombustivel: "",
            fornecedorFiltroOleo: "",
            fundoBg: "",
            imgBandeira: "",
            imgDestaque: "",
            imgModal: "",
            updated_at: "",
    });
    const totalBandeiras: Ref<number> =ref(0)
    const inputFind: Ref<string> = ref('')

    async function getAllBandeiras(pageParam: number, limite: number, input: string) {
      try {
        const { data } = await Api.post("bandeirasPaginado", {pagina: pageParam, limite, input: input});
        bandeiras.value = data.original.bandeiras;
        totalBandeiras.value = data.original.totalBandeiras
      } catch (error) {
        console.log(error);
      }
    }

    async function clickedBandeira(id: number | null) {
      try {
        const {data} = await Api.get('getBandeira', {codBandeira: id})
        bandeiraSelected.value = data        
      } catch (error) {
        console.log(error)
      }
    }

    function usePagination() {
      const state = ref({ currentPage: 1, itemsPerPage: 10, itemsOnPage: 0,});
      const pageCount = computed(() => Math.ceil(totalBandeiras.value / state.value.itemsPerPage));
      const itemsOnPage = computed(() => {
        const startIndex = (state.value.currentPage - 1) * state.value.itemsPerPage;
        const endIndex = startIndex + state.value.itemsPerPage;
        return bandeiras.value && bandeiras.value.slice(startIndex, endIndex);
      });

      const setCurrentPage = page => {
        state.value.currentPage = page;
        state.value.itemsOnPage = itemsOnPage.value.length;
      };

      return {state,setCurrentPage,pageCount,itemsOnPage}
    }

    const { state, setCurrentPage, pageCount, itemsOnPage } = usePagination();

    
    const openModal = (id, type = 'default') => {
      if(type === 'create'){
        bandeiraSelected.value = {
            codBandeira: null,
            cor1: "",
            cor2: "",
            cor3: "",
            created_at: "",
            descricao: "",
            fornecedorFiltroAc: "",
            fornecedorFiltroAr: "",
            fornecedorFiltroCombustivel: "",
            fornecedorFiltroOleo: "",
            fundoBg: "",
            imgBandeira: "",
            imgDestaque: "",
            imgModal: "",
            updated_at: "",
          }
        }
      const auxElement = document.querySelector(`#${id}`);
      auxModal.value = new Modal(auxElement);
      auxModal.value.show();

    };
    
    function closeModal(): void {
      auxModal.value.hide()
      auxModal.value = null
    }

    emitter.on("removeBandeiraLista", (valor: any) => {

      const index = bandeiras.value.findIndex(({codBandeira}) => codBandeira === valor.id)

      if(index !== -1) bandeiras.value.splice(index,1)

      })
    
    watch(
      () => state.value.currentPage,
      () => getAllBandeiras(state.value.currentPage, state.value.itemsPerPage, inputFind.value)
    )
    
    watch(
      () => inputFind.value,
      () => {
        if(inputFind.value.length > 2 || !inputFind.value) {
            getAllBandeiras(state.value.currentPage, state.value.itemsPerPage, inputFind.value)
        }
      }
    )
    
    onMounted(() => getAllBandeiras(state.value.currentPage, state.value.itemsPerPage, inputFind.value))
    
    return { bandeiras, clickedBandeira, state, setCurrentPage, pageCount, itemsOnPage, totalBandeiras, inputFind, openModal, closeModal, bandeiraSelected , getAllBandeiras};
  },
});
