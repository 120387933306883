

import { defineComponent, Ref, ref, watch } from "vue";
import Api from "@/services/Api";
import useAlert from "@/composables/Alert";
import Button from "@/components/Button.vue"
import axios from "axios"
import useEmitter from "@/composables/Emmiter";
import type { PropType } from 'vue'

interface Bandeira {
  codBandeira: number | null;
  cor1: string;
  cor2: string;
  cor3: string;
  created_at: string;
  descricao: string;
  fornecedorFiltroAc: string | null;
  fornecedorFiltroAr: string | null;
  fornecedorFiltroCombustivel: string | null;
  fornecedorFiltroOleo: string | null;
  fundoBg: string;
  imgBandeira: string;
  imgDestaque: string;
  imgModal: string | null;
  updated_at: string;
}

export default defineComponent({
  name: "ModalCreateEditBandeira",
  components: {
    Button,
  },
  emits: [],
  props: {
    bandeiraSelected: Object as PropType<Bandeira>,
    closeModal: Function,
    getAllBandeiras: Function
  },

  setup(props) {

    const { showTimeAlert } = useAlert();
    const spanWarning = ref(false);
    const loadSalvar: Ref<boolean> = ref(false);
    const dialogVisible: Ref<boolean> = ref(false);
    const emitter = useEmitter()
    const bandeiraSelected: Ref<any> = ref({});
    const fileRefs: Ref<any> = ref({}) 


    async function getToken() {
        return localStorage.getItem('token_usuario');
    };

    async function createOrUpdate(event: any) {
      event.preventDefault()
      loadSalvar.value = true

      const formData = new FormData(event.target);
   
      const {data} = await axios.post('bandeira/createOrUpdate', formData, {
        headers: {
          Authorization: `Bearer ${await getToken()}`,
        }
      });

      loadSalvar.value = false

      if(!data.data.codBandeira) return showTimeAlert('Erro ao salvar bandeira!', "erro")
      props.getAllBandeiras && props.getAllBandeiras(1,20,'')
    
      fechaModal()

      if(props.bandeiraSelected?.codBandeira) return showTimeAlert('Bandeira Atualizada com sucesso!', "success")
      return showTimeAlert('Bandeira criada com sucesso!', "success")
    }

    async function deletarBandeira(idBandeira: number) { 

      const { data } = await Api.delete("deleteBandeira", { codBandeira:  idBandeira});

      if(!data) return showTimeAlert(data?.message, "error");
      removeBandeiraLista(idBandeira);

      dialogVisible.value = false;
      fechaModal();

      return showTimeAlert('Bandeira deletada com sucesso!', "success");
    }
    
    function removeBandeiraLista(idBandeira: number) {
      emitter.emit('removeBandeiraLista', { id: idBandeira })
    }

    function abreModal(){
      bandeiraSelected.value = { ...props.bandeiraSelected } 
    }

    function fechaModal(){
      bandeiraSelected.value = {};
      props.closeModal && props.closeModal()
    }

    function handleImgFileChange(event: Event, refParam: string) {
        const fileInput = event.target as HTMLInputElement;
        const file = fileInput.files?.[0];
        if (file) {
            fileRefs.value[refParam] = file
        }
    }

    watch(() => props.bandeiraSelected, async () => { abreModal() }, { deep: true })
    watch(() => props.bandeiraSelected?.codBandeira, async () => { fileRefs.value = {}}, { deep: true })

    return {
      spanWarning,
      dialogVisible,
      createOrUpdate,
      handleImgFileChange,
      bandeiraSelected,
      deletarBandeira,
      loadSalvar,
      fileRefs
    };
  },
});
